import 'core-js/stable'
import Vue from 'vue'
import App from './App'
import router from './router'
import CoreuiVue from '@coreui/vue'
import { iconsSet as icons } from './assets/icons/icons.js'
import store from './store'
import Element from 'element-ui'
import enLang from 'element-ui/lib/locale/lang/en'
import VueSession from "vue-session";

import './styles/element-variables.scss'
import '@/styles/index.scss' // global css
const moment = require('moment')
require('moment/locale/th')

Vue.use(require('vue-moment'), {
  moment
})

Vue.config.performance = true
Vue.use(CoreuiVue)
Vue.use(VueSession);
Vue.prototype.$log = console.log.bind(console)

Vue.use(Element, {
  locale: enLang
})

new Vue({
  el: '#app',
  router,
  store,
  icons,
  template: '<App/>',
  components: {
    App
  }
})
