import Vue from 'vue'
import Router from 'vue-router'

// Containers
const TheContainer = () => import('@/containers/TheContainer')

// Views
const Main = () => import('@/views/pages/Main')
const Dashboard = () => import('@/views/Dashboard')
const DashboardAdmin = () => import('@/views/admin/Dashboard')
const DashboardOld = () => import('@/views/Dashboardold')

// Step1
const CheckDocument = () => import('@/views/step1/CheckDocument')
const CheckDocumentDetail = () => import('@/views/step1/CheckDocumentDetail')
// Step2
const TransportDoc = () => import('@/views/step2/TransportDoc')
const TransportDocDetail = () => import('@/views/step2/TransportDocDetail')
//step3
const CarRegistration = () => import('@/views/step3/CarRegistration')
const CarRegistrationDetail = () => import('@/views/step3/CarRegistrationDetail')
const AssignTask = () => import('@/views/step3/AssignTask')
const AssignTaskDetail = () => import('@/views/step3/AssignTaskDetail')
const CheckWork = () => import('@/views/step3/CheckWork')
//step4
const CustomerAppointment = () => import('@/views/step4/CustomerAppointment')
const CustomerAppointmentDetail = () => import('@/views/step4/CustomerAppointmentDetail')
const TransferCar = () => import('@/views/step4/TransferCar')
const TransferCarDetail = () => import('@/views/step4/TransferCarDetail')
//step5
const TransferLoan = () => import('@/views/step5/TransferLoan')
const TransferLoanDetail = () => import('@/views/step5/TransferLoanDetail')
const UploadEvidence = () => import('@/views/step5/UploadEvidence')
const UploadEvidenceDetail = () => import('@/views/step5/UploadEvidenceDetail')
const WrongContract = () => import('@/views/step5/WrongContract')
const WrongContractDetail = () => import('@/views/step5/WrongContractDetail')
//HR
const Commission = () => import('@/views/stepHR/Commission')
const CommissionDetail = () => import('@/views/stepHR/CommissionDetail')
const TransferCarReport =() => import('@/views/stepHR/TransferCarReport')
//CO
const CommissionCo = () => import('@/views/stepCO/Commission')
const CommissionCoDetail = () => import('@/views/stepCO/CommissionDetail')
//marketing
const CheckWorkMarketing = () => import('@/views/marketing/CheckWork')
const CheckWorkMarketingDetail = () => import('@/views/marketing/CheckWorkDetail')
//skipStep
const SkipStep = () => import('@/views/skipStep/SkipStep')
const SkipStepDetail = () => import('@/views/skipStep/SkipStepDetail')

const Colors = () => import('@/views/base/theme/Colors')
const Typography = () => import('@/views/base/theme/Typography')

const Charts = () => import('@/views/base/charts/Charts')
const Widgets = () => import('@/views/base/widgets/Widgets')

// Views - Components
const Cards = () => import('@/views/base/Cards')
const Forms = () => import('@/views/base/Forms')
const Switches = () => import('@/views/base/Switches')
const Tables = () => import('@/views/base/Tables')
const DataTables = () => import('@/views/base/DataTables')
const Tabs = () => import('@/views/base/Tabs')
const Breadcrumbs = () => import('@/views/base/Breadcrumbs')
const Carousels = () => import('@/views/base/Carousels')
const Collapses = () => import('@/views/base/Collapses')
const Jumbotrons = () => import('@/views/base/Jumbotrons')
const ListGroups = () => import('@/views/base/ListGroups')
const Navs = () => import('@/views/base/Navs')
const Navbars = () => import('@/views/base/Navbars')
const Paginations = () => import('@/views/base/Paginations')
const Popovers = () => import('@/views/base/Popovers')
const ProgressBars = () => import('@/views/base/ProgressBars')
const Tooltips = () => import('@/views/base/Tooltips')

// Views - Buttons
const StandardButtons = () => import('@/views/base/buttons/StandardButtons')
const ButtonGroups = () => import('@/views/base/buttons/ButtonGroups')
const Dropdowns = () => import('@/views/base/buttons/Dropdowns')
const BrandButtons = () => import('@/views/base/buttons/BrandButtons')

// Views - Icons
const CoreUIIcons = () => import('@/views/base/icons/CoreUIIcons')
const Brands = () => import('@/views/base/icons/Brands')
const Flags = () => import('@/views/base/icons/Flags')

// Views - Notifications
const Alerts = () => import('@/views/base/notifications/Alerts')
const Badges = () => import('@/views/base/notifications/Badges')
const Modals = () => import('@/views/base/notifications/Modals')

// Views - Pages
const Page404 = () => import('@/views/pages/Page404')
const Page500 = () => import('@/views/pages/Page500')
const Login = () => import('@/views/pages/Login')
const UserLogin = () => import('@/views/pages/UserLogin')
const Register = () => import('@/views/pages/Register')
const Sync = () => import('@/views/pages/SyncData')

// Users
const Users = () => import('@/views/admin/Users')
const User = () => import('@/views/admin/User')

// Customer
const Contracts = () => import('@/views/user/Contracts')
const Contract = () => import('@/views/user/Contract')

Vue.use(Router)

const router = new Router({
  mode: 'history', // https://router.vuejs.org/api/#mode
  linkActiveClass: 'active',
  scrollBehavior: () => ({ y: 0 }),
  routes: configRoutes()
});

router.beforeEach((to, from, next) => {
  const session = sessionStorage.getItem("vue-session-key")
  const obj = JSON.parse(session)
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (obj.token == null) {
      next({
        path: "/login",
        params: { nextUrl: to.fullPath },
      });
    } else {
      next();
    }
  } else {
    if (to.path === '/login' && (obj && obj.token)) {
      next({
        path: "/dashboard",
        params: { nextUrl: to.fullPath },
      });
    } else {
      next();
    }
  }
});

function configRoutes () {
  return [
    {
      path: '/',
      redirect: '/main',
      name: 'Home',
      component: TheContainer,
      meta: {
        requiresAuth: true,
      },
      children: [
        {
          path: 'dashboard',
          name: 'Dashboard',
          component: Dashboard
        },
        {
          path: 'dashboard-old',
          name: 'DashboardOld',
          component: DashboardOld
        },
        {
          path: 'step1',
          redirect: '/step1/CheckDocument',
          name: 'Step 1',
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: 'CheckDocument',
              meta: {
                label: 'ตรวจสอบเอกสาร'
              },
              name: 'CheckDocument',
              component: CheckDocument
            },
            {
              path: 'CheckDocumentDetail/:id',
              meta: {
                label: 'ตรวจสอบเอกสาร'
              },
              name: 'CheckDocumentDetail',
              component: CheckDocumentDetail
            }
          ]
        },
        {
          path: 'step2',
          redirect: '/step2/TransportDoc',
          name: 'Step 2',
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: 'TransportDoc',
              meta: {
                label: 'วางชุดโอน'
              },
              name: 'TransportDoc',
              component: TransportDoc
            },
            {
              path: 'TransportDocDetail/:id',
              meta: {
                label: 'วางชุดโอน'
              },
              name: 'TransportDocDetail',
              component: TransportDocDetail
            }
          ]
        },
        {
          path: 'step3',
          redirect: '/step3/TransferSet',
          name: 'Step 3',
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: 'CarRegistration',
              meta: {
                label: 'รับเล่มทะเบียน'
              },
              name: 'CarRegistration',
              component: CarRegistration
            },
            {
              path: 'CarRegistrationDetail/:id',
              meta: {
                label: 'รับเล่มทะเบียน'
              },
              name: 'CarRegistrationDetail',
              component: CarRegistrationDetail
            },
            {
              path: 'AssignTask',
              meta: {
                label: 'มอบหมายงาน'
              },
              name: 'AssignTask',
              component: AssignTask
            },
            {
              path: 'AssignTaskDetail/:id',
              meta: {
                label: 'มอบหมายงาน'
              },
              name: 'AssignTaskDetail',
              component: AssignTaskDetail
            },
            {
              path: 'CheckWork',
              meta: {
                label: 'ตรวจสอบการทำงาน'
              },
              name: 'CheckWork',
              component: CheckWork
            }
          ]
        },
        {
          path: 'step4',
          redirect: '/step4/CustomerAppointment',
          name: 'Step 4',
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: 'CustomerAppointment',
              meta: {
                label: 'นัดหมายลูกค้า'
              },
              name: 'CustomerAppointment',
              component: CustomerAppointment
            },
            {
              path: 'CustomerAppointmentDetail/:id',
              meta: {
                label: 'นัดหมายลูกค้า'
              },
              name: 'CustomerAppointmentDetail',
              component: CustomerAppointmentDetail
            },
            {
              path: 'TransferCar',
              meta: {
                label: 'การโอนรถ'
              },
              name: 'TransferCar',
              component: TransferCar
            },
            {
              path: 'TransferCarDetail/:id',
              meta: {
                label: 'การโอนรถ'
              },
              name: 'TransferCarDetail',
              component: TransferCarDetail
            },
          ]
        },
        {
          path: 'step5',
          redirect: '/step5/TransferLoan',
          name: 'Step 5',
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: 'TransferLoan',
              meta: {
                label: 'โอนเงินสินเชื่อ'
              },
              name: 'TransferLoan',
              component: TransferLoan
            },
            {
              path: 'TransferLoanDetail/:id',
              meta: {
                label: 'โอนเงินสินเชื่อ'
              },
              name: 'TransferLoanDetail',
              component: TransferLoanDetail
            },
            {
              path: 'UploadEvidence',
              meta: {
                label: 'อัพโหลดหลักฐาน'
              },
              name: 'UploadEvidence',
              component: UploadEvidence
            },
            {
              path: 'UploadEvidenceDetail/:id',
              meta: {
                label: 'อัพโหลดหลักฐาน'
              },
              name: 'UploadEvidenceDetail',
              component: UploadEvidenceDetail
            },
            {
              path: 'WrongContract',
              meta: {
                label: 'สัญญาไม่เรียบร้อย'
              },
              name: 'WrongContract',
              component: WrongContract
            },
            {
              path: 'WrongContractDetail/:id',
              meta: {
                label: 'สัญญาไม่เรียบร้อย'
              },
              name: 'WrongContractDetail',
              component: WrongContractDetail
            },
          ]
        },
        {
          path: 'stepHR',
          redirect: '/stepHR/Commission',
          name: 'HR',
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: 'Commission',
              meta: {
                label: 'ค่าคอมมิชชั่น'
              },
              name: 'Commission',
              component: Commission
            },
            {
              path: 'CommissionDetail/:id',
              meta: {
                label: 'ค่าคอมมิชชั่น'
              },
              name: 'CommissionDetail',
              component: CommissionDetail
            },
            {
              path: 'TransferCarReport',
              meta: {
                label: 'รายงานการโอนรถ'
              },
              name: 'TransferCarReport',
              component: TransferCarReport
            }
          ]
        },
        {
          path: 'stepCO',
          redirect: '/stepCO/Commission',
          name: 'CO',
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: 'Commission',
              meta: {
                label: 'ค่าคอมมิชชั่น'
              },
              name: 'CommissionCO',
              component: CommissionCo
            },
            {
              path: 'CommissionDetail/:id',
              meta: {
                label: 'ค่าคอมมิชชั่น'
              },
              name: 'CommissionDetailCO',
              component: CommissionCoDetail
            }
          ]
        },
        {
          path: 'marketing',
          redirect: '/marketing/CheckWork',
          name: 'marketing',
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: 'CheckWork',
              meta: {
                label: 'ตรวจสอบการทำงาน'
              },
              name: 'MarketingCheckWork',
              component: CheckWorkMarketing
            },
            {
              path: 'CheckWorkDetail/:id',
              meta: {
                label: 'ตรวจสอบการทำงาน'
              },
              name: 'MarketingCheckWorkDetail',
              component: CheckWorkMarketingDetail
            }
          ]
        },
        {
          path: 'skipStep',
          redirect: '/skipStep/',
          name: 'ข้ามขั้นตอน',
          component: {
            render (c) { return c('router-view') }
          },
          children: [
           {
             path: '/',
             meta: {
               label: 'รายการ'
             },
             name: 'skipStep',
             component: SkipStep
           },
           {
             path: ':id',
             meta: {
               label: 'รายละเอียด'
             },
             name: 'skipStepDetail',
             component: SkipStepDetail
           }
          ]
        },
        {
          path: 'theme',
          redirect: '/theme/colors',
          name: 'Theme',
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: 'colors',
              name: 'Colors',
              component: Colors
            },
            {
              path: 'typography',
              name: 'Typography',
              component: Typography
            }
          ]
        },
        {
          path: 'charts',
          name: 'Charts',
          component: Charts
        },
        {
          path: 'widgets',
          name: 'Widgets',
          component: Widgets
        },
        {
          path: 'admin',
          meta: {
            label: 'Admin'
          },
          component: {
            render(c) {
              return c('router-view')
            }
          },
          children: [
            {
              path: 'dashboard',
              name: 'Dashboard (Admin)',
              component: DashboardAdmin
            },
            {
              path: 'users',
              meta: {
                label: 'Users'
              },
              component: {
                render(c) {
                  return c('router-view')
                }
              },
              children: [
                {
                  path: '',
                  name: 'UserLists',
                  component: Users
                },
                {
                  path: 'add',
                  meta: {
                    label: 'Add User'
                  },
                  name: 'AddUserDetail',
                  component: User
                },
                {
                  path: 'detail/:id',
                  meta: {
                    label: 'User Details'
                  },
                  name: 'UserDetail',
                  component: User
                }
              ]
            },
          ]
        },
        {
          path: 'base',
          redirect: '/base/cards',
          name: 'Base',
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: 'cards',
              name: 'Cards',
              component: Cards
            },
            {
              path: 'forms',
              name: 'Forms',
              component: Forms
            },
            {
              path: 'switches',
              name: 'Switches',
              component: Switches
            },
            {
              path: 'tables',
              name: 'Tables',
              component: Tables
            },
            {
              path: 'datatables',
              name: 'DataTables',
              component: DataTables
            },
            {
              path: 'tabs',
              name: 'Tabs',
              component: Tabs
            },
            {
              path: 'breadcrumbs',
              name: 'Breadcrumbs',
              component: Breadcrumbs
            },
            {
              path: 'carousels',
              name: 'Carousels',
              component: Carousels
            },
            {
              path: 'collapses',
              name: 'Collapses',
              component: Collapses
            },
            {
              path: 'jumbotrons',
              name: 'Jumbotrons',
              component: Jumbotrons
            },
            {
              path: 'list-groups',
              name: 'List Groups',
              component: ListGroups
            },
            {
              path: 'navs',
              name: 'Navs',
              component: Navs
            },
            {
              path: 'navbars',
              name: 'Navbars',
              component: Navbars
            },
            {
              path: 'paginations',
              name: 'Paginations',
              component: Paginations
            },
            {
              path: 'popovers',
              name: 'Popovers',
              component: Popovers
            },
            {
              path: 'progress-bars',
              name: 'Progress Bars',
              component: ProgressBars
            },
            {
              path: 'tooltips',
              name: 'Tooltips',
              component: Tooltips
            }
          ]
        },
        {
          path: 'buttons',
          redirect: '/buttons/standard-buttons',
          name: 'Buttons',
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: 'standard-buttons',
              name: 'Standard Buttons',
              component: StandardButtons
            },
            {
              path: 'button-groups',
              name: 'Button Groups',
              component: ButtonGroups
            },
            {
              path: 'dropdowns',
              name: 'Dropdowns',
              component: Dropdowns
            },
            {
              path: 'brand-buttons',
              name: 'Brand Buttons',
              component: BrandButtons
            }
          ]
        },
        {
          path: 'icons',
          redirect: '/icons/coreui-icons',
          name: 'CoreUI Icons',
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: 'coreui-icons',
              name: 'Icons library',
              component: CoreUIIcons
            },
            {
              path: 'brands',
              name: 'Brands',
              component: Brands
            },
            {
              path: 'flags',
              name: 'Flags',
              component: Flags
            }
          ]
        },
        {
          path: 'notifications',
          redirect: '/notifications/alerts',
          name: 'Notifications',
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: 'alerts',
              name: 'Alerts',
              component: Alerts
            },
            {
              path: 'badges',
              name: 'Badges',
              component: Badges
            },
            {
              path: 'modals',
              name: 'Modals',
              component: Modals
            }
          ]
        },
        {
          path: 'sync',
          name: 'Sync Data',
          component: Sync
        }
      ]
    },
    {
      path: '/user',
      redirect: '/user/login',
      name: 'ลูกค้า',
      component: {
        render (c) { return c('router-view') }
      },
      children: [
        {
          path: 'login',
          name: 'สัญญา',
          component: UserLogin
        },
        {
          path: 'contracts',
          meta: {
            requiresAuth: true,
            label: 'สัญญาของฉัน'
          },
          component: TheContainer,
          children: [
            {
              path: '',
              name: 'สัญญาของฉัน',
              component: Contracts
            },
            {
              path: ':id',
              meta: {
                label: 'รายละเอียดสัญญา'
              },
              name: 'Contract',
              component: Contract
            }
          ]
        },
      ]
    },
    {
      path: '/',
      redirect: '/main',
      name: 'Pages',
      component: {
        render (c) { return c('router-view') }
      },
      children: [
        {
          path: '500',
          name: 'Page500',
          component: Page500
        },
        {
          path: 'main',
          name: 'Main',
          component: Main
        },
        {
          path: 'login',
          name: 'Login',
          component: Login
        },
        {
          path: 'register',
          name: 'Register',
          component: Register
        }
      ]
    },
    {
      path: '*',
      redirect: '/404',
      name: 'NotFoundPages',
      component: {
        render (c) { return c('router-view') }
      },
      children: [
        {
          path: '404',
          name: 'Page404',
          component: Page404
        }
      ]
    }
  ]
}

export default router;
