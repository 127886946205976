// Example of SVG converted to js array, so it can be used with CIcon.
// the first argument is two last values of svg viewBox,
// the second argument is the SVG content stripped of SVG tags
export const logo = ['580 134', `
 <g>
  <title>ONE Logo</title>
  <text font-weight="bold" xml:space="preserve" text-anchor="start" font-family="'Arial'" font-size="100" id="svg_1" y="103" x="5" stroke-width="0" stroke="#000" style="fill:currentColor;">ONE</text>
  <text transform="matrix(1 0 0 1 0 0)" xml:space="preserve" text-anchor="start" font-family="'Arial'" font-size="30" id="svg_2" y="56" x="225" stroke-width="0" stroke="#000" style="fill:currentColor;">บริษัท วัน ลิสซิ่ง จำกัด</text>
  <text stroke="#000" xml:space="preserve" text-anchor="start" font-family="'Arial'" font-size="30" id="svg_3" y="100" x="225" stroke-width="0" style="fill:currentColor;">ONE  LEASING  CO.,LTD.</text>
 </g>	
`]
